// document.addEventListener("DOMContentLoaded", function() {
//   onScroll();
// });

// TODO: maybe this should be a service, which can then be called on every route?

// window.onscroll = function(e) {
//   onScroll(e);
// }

function onScroll(e) {
  // console.log('test');
  var bodyRect = document.body.getBoundingClientRect();
  var parallaxItems = document.querySelectorAll('.parallax--item');
  // console.log(bodyRect, parallaxItems);
  [].forEach.call(parallaxItems, function(element) {
    // console.log(element);
    if (checkVisibleItem(element, 0, 'visisble')) {
      var dif = element.getBoundingClientRect().top - ( document.body.scrollTop + window.innerHeight/2 );
      // console.log(element, element.getBoundingClientRect().top, -dif/10);
      var speed = element.getAttribute('data-speed');
      element.style.setProperty('transform', 'translate3d(0px, ' + -dif/speed + 'px, 0px)');
    }
  });
  var bodyHeight = window.innerHeight;
  var collectionItems = document.querySelectorAll('.collectionitem--cover');
  // console.log(bodyHeight);
  [].forEach.call(collectionItems, function(element) {
    if (checkVisibleItem(element, 0, 'visible')) {
      // console.log('visible');
      var heightOfContainer = element.offsetHeight;
      var unmappedFinalY = element.getBoundingClientRect().top;
      // console.log(unmappedFinalY);
      // console.log(heightOfContainer);
      // var mappedFinalY = (unmappedFinalY - -1) * (1 - -1) / (1 - 0) + -1;
      var mappedFinalY = scale(unmappedFinalY, 0, bodyHeight, 0, 1);
      // console.log(mappedFinalY);
      element.parentNode.style.setProperty('--y', mappedFinalY);
    }
  });
}

var scale = (num, in_min, in_max, out_min, out_max) => {
  return (num - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
}

function checkVisibleItem(elm, threshold, mode) {
  threshold = threshold || 0;
  mode = mode || 'visible';

  var rect = elm.getBoundingClientRect();
  var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
  var above = rect.bottom - threshold < 0;
  var below = rect.top - viewHeight + threshold >= 0;

  return mode === 'above' ? above : (mode === 'below' ? below : !above && !below);
}
